import React from "react";
import AboutBlock2 from "components/about_block_2";

const Footer = () => {
  return (
    <>
      <AboutBlock2 />
      <footer>
        <div className="footer-area-top bg-dark-accent100">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="footer-box">
                  <div className="footer-logo">
                    <a href="/">
                      <img
                        srcSet={
                          "/img/logo/finstead_risk_solutions_light.svg 2x"
                        }
                        alt="logo"
                        className="img-fluid"
                        style={{ maxWidth: "200px" }}
                      />
                    </a>
                  </div>
                  <div className="footer-about">
                    <hr
                      style={{
                        opacity: "1",
                        backgroundColor: "#D9973E",
                        borderColor: "#D9973E",
                        maxWidth: "237px",
                      }}
                    />

                    <p>Finstead Risk Solutions Pty Ltd</p>
                    <p style={{ color: "#AAAAAA" }}>
                      ABN: 37 098 080 418
                      {/* <br />
                      ACN: 098080418 */}
                      <br />
                      AFSL: 238549
                    </p>
                  </div>
                  <div className="footer-social">
                    <ul>
                      {/* <li>
                            <a href="#" title="Facebook">
                                <i className="fab fa-facebook-f" />
                            </a>
                            </li> */}
                      {/* <li>
                            <a href="#" title="Twitter">
                                <i className="fab fa-twitter" />
                            </a>
                            </li> */}
                      {/* <li>
                        <a
                          href="https://www.linkedin.com/company/finstead-capital"
                          title="Linkedin"
                        >
                          <i className="fab fa-linkedin-in" />
                        </a>
                      </li> */}
                      {/* <li>
                            <a href="#" title="Pinterest">
                                <i className="fab fa-pinterest-p" />
                            </a>
                            </li> */}
                      {/* <li>
                            <a href="#" title="Skype">
                                <i className="fab fa-skype" />
                            </a>
                            </li> */}
                      {/* <li>
                            <a href="#" title="Youtube">
                                <i className="fab fa-youtube" />
                            </a>
                            </li> */}
                      {/* <li>
                        <a
                          href="https://instagram.com/finsteadcapital"
                          title="Instagram"
                        >
                          <i className="fab fa-instagram" />
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                {/* <div className="footer-box">
                        <h3 className="title-medium size-md color-light-accent title-bar-md pseudo-bg-accent100">Latest News</h3> 
                        <div className="latest-news">
                        <ul>
                            <li><a href="#">Small Business Is Making more money.</a><span>April 11, 2018</span></li>
                            <li><a href="#">Business for long time happend.</a><span>April 13, 2018</span></li>
                            <li><a href="#">For Making more money doing small business </a><span>April 15, 2018</span></li>
                        </ul>
                        </div>                                   
                    </div> */}
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="footer-box">
                  <h3 className="title-medium size-md color-light-accent title-bar-md pseudo-bg-accent100">
                    Useful Links
                  </h3>
                  <div className="useful-link">
                    <ul>
                      {/* <li>
                        <a href="/">Home</a>
                      </li>
                      <li>
                        <a href="/about">About Us</a>
                      </li>
                      <li>
                        <a href="/services">Services</a>
                      </li>
                      <li>
                        <a href="/our-value">Our Values</a>
                      </li> */}
                      <li>
                        <a href="/contact">Contact</a>
                      </li>
                      <li>
                        <a href="/privacy">Our Privacy Policy</a>
                      </li>
                      <li>
                        <a href="https://cdn.finsteadrs.com.au/insurance-documents/20240530+Finsteadrs+FSG+0524.pdf">
                          Financial Services Guide
                        </a>
                      </li>
                      <li>
                        <a href="https://finstead.com.au/" target="_blank">
                          Finstead Capital
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="footer-box">
                  <h3 className="title-medium size-md color-light-accent title-bar-md pseudo-bg-accent100">
                    Our Details
                  </h3>
                  <div className="footer-address">
                    <h4>Australia</h4>
                    <ul className="margin-b-20">
                      <li>
                        <i className="fas fa-map-marker-alt" />
                        L11, 56 Pitt Street, Sydney NSW 2000 Australia <br />
                        (Head Office)
                      </li>
                      <li>
                        <i className="fas fa-map-marker-alt" />
                        226/14 Lexington Dr, Bella Vista NSW 2153
                      </li>
                      <li>
                        <i className="fas fa-map-marker-alt" />
                        Level 8, 356 Collins Street, Melbourne VIC 3000
                      </li>
                      <li>
                        <i className="fas fa-phone-volume" />
                        <a
                          href="tel:+611300906963"
                          className="phone-number"
                          style={{ color: "#D9973E" }}
                        >
                          1300 906 963
                        </a>
                      </li>
                      <li>
                        <i className="far fa-envelope" />
                        contact@finsteadrs.com.au
                      </li>
                    </ul>
                    {/* Provisional placing for go ahead to allow this referral */}
                    <a
                      style={{ opacity: 0 }}
                      href="https://www.smebusinessloansaustralia.com/"
                    >
                      SME Business Loans Australia
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copy-right bg-dark-box100 text-center">
          <p>© 2022 Finstead Risk Solutions Pty Ltd. All Rights Reserved.</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
