import React from "react";
import "./styles.css";
import useWindowSize from "hooks/use_window_size";

const Header = ({ partner, pbfs }) => {
  const size = useWindowSize();

  const callFinstead = () => {
    window.location.href = "tel:+611300906963";
  };

  return (
    <>
      {size?.width < 990 && (
        <div className="fs-mobile-phone-mobile" onClick={callFinstead}>
          <i className="fa fa-phone" />
        </div>
      )}
      <header>
        <div
          id="header-one"
          className="header-height-wrap header-area header-fixed header-layout1"
        >
          <div className="mobile-phone-button">
            <i className="fa fa-phone-circle" />
          </div>

          <div className="main-menu-wrap" id="sticker">
            <div className="container">
              <div className="row no-gutters d-flex align-items-center">
                {partner ? (
                  <div className="col-4">
                    <div className="logo-area">
                      <a
                        href="/"
                        className="logo-dark"
                        style={{
                          borderRight: "2px solid #e2e2e2",
                          marginRight: "15px",
                        }}
                      >
                        <img
                          src={partner.logo_url_dark}
                          alt="logo"
                          className="img-fluid"
                          style={{
                            marginRight: "15px",
                            maxWidth: "90px",
                            ...partner.logo_url_light_config,
                          }}
                        />
                      </a>
                      <a href="/" className="logo-dark">
                        <img
                          src="https://cdn.finstead.com.au/media/finstead.png"
                          alt="logo"
                          className="img-fluid"
                          style={{ maxWidth: "150px", width: "150px" }}
                        />
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="col-lg-2 col-md-2">
                    <div className="logo-area">
                      {/* <Link to="/"> */}
                      <a href="/" className="logo-dark">
                        <img
                          src={"/img/logo/finstead_risk_solutions_dark.svg"}
                          alt="logo"
                          className="img-fluid"
                          style={{ maxWidth: "210px" }}
                        />
                      </a>
                      {/* </Link> */}
                    </div>
                  </div>
                )}
                <div
                  className={
                    partner
                      ? "col-lg-5 col-md-5 possition-static"
                      : "col-lg-7 col-md-7 possition-static"
                  }
                >
                  <div className="template-main-menu">
                    <nav id="dropdown">
                      <ul>
                        <li>
                          <a href="/about">About</a>
                        </li>
                        <li>
                          <a href="/services">Services</a>
                        </li>
                        <li>
                          <a href="/values">Our Values</a>
                        </li>
                        <li>
                          <a href="/contact">Contact</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3">
                  <div className="header-action-items text-right">
                    <ul>
                      <li>
                        <a
                          href="tel:+611300906963"
                          className="
                                    custom-size-md btn-fill 
                                    bg-accent border-color-accent radius-45 
                                    color-light hover-color-accent btn-icon size-xs icon-left
                                    custom-call-button
                                "
                        >
                          <i className="fas fa-phone" />
                          <span className="call-number-text">1300 906 963</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
