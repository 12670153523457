import React, {useEffect} from "react"

import { withPrefix } from "gatsby"
import { Helmet } from 'react-helmet';

import Header from 'components/header';
import Footer from 'components/footer';

import "./css/normalize.css";
import "./css/bootstrap.min.css";
import "./css/main.css";
import "./css/animate.min.css";
import "./css/fontawesome-all.min.css";
import "./css/font/flaticon.css";
import "./css/owl.carousel.min.css";
import "./css/owl.theme.default.min.css";
import "./css/meanmenu.min.css";
import "./css/nivo-slider.css";
import "./css/preview.css";
import "./css/nouislider.min.css";
import "./css/select2.min.css";
import "./css/magnific-popup.css";
import "./css/color.css";
import "./css/elements.css";
import "./css/styles.css"
import 'css/index.css';

export default function Layout({ children, headerOverride }) {
    useEffect(() => {
        if(typeof(window) !== 'undefined'){window.scrollTo(0, 0)}
      }, [])

    return <div id="wrapper" className="wrapper">
        <Helmet>
            {/* 
                All javascript libraries have been pre-compiled into all.min.js
                The intention is to replace these libraries with react specific libraries and component solutions
                one by one until all.js is no longer required. The included libraries are as follows:
                
                jquery-2.2.4.min.js, plugins.js, popper.js, bootstrap.min.js, jquery.nivo.slider.js, slider/home.js, owl.carousel.min.js
                jquery.meanmenu.min.js, jquery.scrollUp.min.js, jquery.counterup.min.js, waypoints.min.js, fontawesome-all.min.js, validator.min.js
                parallaxie.js, imagesloaded.pkgd.min.js, jquery.magnific-popup.min.js, isotope.pkgd.min.js, jquery.hoverdir.min.js, nouislider.min.js
                wNumb.js, select2.min.js, chart.min.js, main.js

                These libraries are visiable individually in the root folder of the repository in the assets directory.
            */}
            <script async={false} src={withPrefix('js/all.js')} type='text/javascript' />
        </Helmet>
        {headerOverride ? headerOverride : <Header />}
        {children}
        <Footer/>
    </div>
}
