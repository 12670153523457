import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const FsAnalytics = () => {
  return (
    <>
      {/* Load gtag into window object */}
      {/* {
                typeof window !== "undefined" && window.location.hostname == 'finstead.com.au' && <>
                    <Helmet><script async src="https://www.googletagmanager.com/gtag/js?id=G-KTY03PBQFZ"></script></Helmet>
                    <Helmet script={[{type: 'text/javascript', innerHTML: "window.dataLayer = window.dataLayer || [];function gtag(){window.dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-KTY03PBQFZ');"}]}/>
                </>
            } */}

      {/* Google Tag Manager for KG2 and Finstead */}
      {/* <Helmet script={[{type: 'text/javascript', innerHTML: FinsteadAndKg2GTMScript }]}/> */}

      {/* Google Tag Manager No script */}
      {/* <Helmet><body>{`<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NDGXGZ6" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`}</body></Helmet> */}

      {/* Pixel for all page visits */}
      {/* <img src="https://ad.doubleclick.net/ddm/activity/src=11256948;type=finsrema;cat=allpages;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="0" height="0" alt="" /> */}

      {/* Lead Page Pixels */}
      {/* {typeof window !== "undefined" && typeof window.gtag !== "undefined" && window.pathname == "/KG2" && <Helmet> */}
      {/* Lead Page Visit Pixel */}
      {/* <img src="https://ad.doubleclick.net/ddm/activity/src=11256948;type=finsconv;cat=pageconv;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/> */}
      {/* Form Submission Pixel ?? Note - waiting for confirmation on the purpose of this pixel */}
      {/* <img src="https://ad.doubleclick.net/ddm/activity/src=11256948;type=finsconv;cat=formconv;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/> */}
      {/* </Helmet>} */}
    </>
  );
};

export default FsAnalytics;
