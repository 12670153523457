import React from "react";
import { Parallax } from "react-parallax";
import ScrollAnimation from "react-animate-on-scroll";

const AboutBlock2 = () => {
  return (
    <div>
      <Parallax
        strength={150}
        blur={0}
        bgImage="/img/values/footer_banner_phone.jpg"
        bgImageAlt="Sydney Opera House"
        bgImageStyle={{ objectFit: "cover", objectPosition: "center bottom" }}
        style={{ objectFit: "cover" }}
      >
        <section className="section-space-default-less30 ">
          <div className="container">
            <div className="zindex-up-7 text-left">
              <div
                className="achievement-area text-left"
                style={{
                  backgroundColor: "rgba(0,0,0,0.7)",
                  padding: "60px 0px 40px 60px",
                  borderRadius: "30px",
                }}
              >
                <br />
                <ScrollAnimation animateIn="fadeIn" animateOnce>
                  <h2
                    className="title size-md color-light-accent"
                    style={{ fontWeight: "300", maxWidth: "440px" }}
                  >
                    Get in touch with one of our expert team today
                  </h2>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeIn">
                  <a className="btn btn-light" href="/contact">
                    Get in Touch
                  </a>
                </ScrollAnimation>
                <br />
                <br />
              </div>
            </div>
          </div>
        </section>
      </Parallax>
    </div>
  );
};

export default AboutBlock2;
